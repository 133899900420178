import React, { useEffect, useState } from 'react';
import './DashboardPage.css';
import { motion, AnimatePresence, cubicBezier } from 'framer-motion';
import Header from '../Components/Header';
import ReturnEnv from '../Components/User/Utilities';
import { Navigate, useNavigate } from 'react-router-dom';
import NavLeft from '../Components/Navigation/NavLeft';
import { barrowsTheme, chartPalette } from '../Components/Themes';
import Crumbs from '../Components/Crumbs';

import { CampaignTemplate01 } from '../Components/CampaignTemplate01';
import { CampaignTemplate02 } from '../Components/CampaignTemplate02';
import { CampaignTemplate03 } from '../Components/CampaignTemplate03';
import { CampaignTemplate04 } from '../Components/CampaignTemplate04';
import { DataGridStyler } from '../Components/DataGridStyler';
import { Selectors } from '../Components/Selectors';

const DashboardPageV2 = (props: any) => {
  const navigate = useNavigate();
  const { displayName, userEmail, userClaim } = props;
  const [claimStatus, setClaimStatus] = React.useState('');
  const [campaignData, setCampaignData] = React.useState({} as any);
  const [campaignTemplate, setCampaignTemplate] = React.useState(Number);

  const [selectedCampaign, setSelectedCampaign] = React.useState({} as any);

  const [campaignNames, setCampaignNames] = React.useState([] as any);
  const [programNames, setProgramNames] = React.useState([] as any);
  const [selectedCampaignName, setSelectedCampaignName] = React.useState('' as any);
  const [selectedProgramName, setSelectedProgramName] = React.useState('' as any);

  const [partnerNames, setPartnerNames] = React.useState([]);
  const [selectedPartnerName, setSelectedPartnerName] = React.useState('' as any);

  const [years, setYears] = React.useState(['2022', '2023', '2024'] as any);
  const [selectedYear, setSelectedYear] = React.useState('2024');

  const [months, setMonths] = React.useState(['June', 'July', 'August', 'September'] as any);
  const [selectedStartMonth, setSelectedStartMonth] = React.useState('June' as any);
  const [selectedEndMonth, setSelectedEndMonth] = React.useState('September' as any);

  const [theme, setTheme] = React.useState(barrowsTheme);
  const [hasTenantChanged, setHasTenantChanged] = React.useState('true');
  const [tenantLogo, setTenantLogo] = React.useState('');
  const [tenantName, setTenantName] = useState('');
  const [tId, setTid] = useState(String);
  const [isCampaignRendered, setIsCampaignRendered] = useState(false);

  let mockData = false as boolean;

  React.useEffect(() => {
    fetch(`${ReturnEnv()}/check-claim`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail, userClaim }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data['Claim Status'] === 'Success') {
          console.log('Claim Success');
          setClaimStatus('Success');
        } else {
          // console.log("Claim Failed");
          setClaimStatus('Failed');
          navigate('/login');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [userEmail, userClaim]);

  useEffect(() => {
    let dataUrl = '';
    if (userEmail === 'mich.ultra@barrowsglobal.com') {
      mockData = true;
    }
    mockData ? (dataUrl = `${ReturnEnv()}/get-mock-campaigns`) : (dataUrl = `${ReturnEnv()}/get-campaigns`);
    fetch(dataUrl, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let transformedData: any = { campaigns: [] };
        data.campaigns.forEach((campaign: any) => {
          if (selectedYear === campaign.year) {
            transformedData.campaigns.push(campaign);
          }
        });
        data = transformedData;
        console.log(transformedData);
        // DataGridStyler(data);
        setCampaignData(transformedData);
        // setSelectedCampaign(data['campaigns'][0]);

        const pNames: any = [];
        const firstPartner = data['campaigns'][0]['partner'];
        const cNames: any = [];
        const prgNames: any = [];
        data['campaigns'].forEach((campaign: any) => {
          if (!pNames.includes(campaign['partner'])) {
            pNames.push(campaign['partner']);
          }
          if (!cNames.includes(campaign['name']) && campaign['partner'] === firstPartner) {
            cNames.push(campaign['name']);
            prgNames.push(campaign['programName']);
          }
        });
        console.log(pNames);
        setPartnerNames(pNames);
        setSelectedPartnerName(pNames[0]);
        // check this out, affects below useEffect
        setCampaignNames(cNames);
        console.log(cNames);
        // setSelectedCampaignName(cNames[0]);
        setProgramNames(prgNames);
        setSelectedProgramName(prgNames[0]);
        setCampaignTemplate(data['campaigns'][0]['template']);
      });
  }, [selectedYear]);

  // BELOW CHANGES CAMPAIGNS

  useEffect(() => {
    if (campaignData['campaigns']) {
      campaignData['campaigns'].forEach((campaign: any) => {
        if (campaign['name'] === campaignNames[0]) {
          setSelectedCampaign(campaign);
          setSelectedProgramName(campaign['programName']);
        }
      });
    }
  }, [campaignNames]);

  const handlePartnerChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedPartnerName(value);
    const cn: any[] = [];
    const prgNames: any = [];
    campaignData['campaigns'].forEach((campaign: any) => {
      if (campaign['partner'] === value) {
        cn.push(campaign['name']);
        prgNames.push(campaign['programName']);
        setCampaignTemplate(campaign['template']);
        console.log(campaign['template']);
      }
    });
    setSelectedCampaignName(cn[0]);
    setCampaignNames(cn);
    setProgramNames(prgNames);
    setSelectedProgramName(prgNames[0]);
    console.log(cn);
  };

  const handleCampaignChange = (event: any) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    // setSelectedCampaignName(value);
    setSelectedProgramName(value);
    campaignData['campaigns'].forEach((campaign: any) => {
      if (campaign['programName'] === value) {
        setSelectedCampaign(campaign);
        setSelectedProgramName(campaign['programName']);
      }
    });
  };

  const handleYearChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedYear(value);
  };

  const handleStartMonthChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedStartMonth(value);
  };

  const handleEndMonthChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedEndMonth(value);
  };

  return (
    <div className="flex flex-col box-border">
      <div className="w-full h-[8px]" style={{ background: theme.palette.primary.dark }}></div>
      <div className="flex box-border">
        <NavLeft
          theme={theme}
          setTheme={setTheme}
          userEmail={userEmail}
          partnerNames={partnerNames}
          handlePartnerChange={handlePartnerChange}
          hasTenantChanged={hasTenantChanged}
          setHasTenantChanged={setHasTenantChanged}
          tenantLogo={tenantLogo}
          setTenantLogo={setTenantLogo}
          tenantName={tenantName}
          setTenantName={setTenantName}
          setTid={setTid}
          tId={tId}
          selectedCampaign={selectedCampaign}
          setIsCampaignRendered={setIsCampaignRendered}
        />
        <div className="flex flex-row w-full box-border">
          <div className="absolute top-2 left-0 text-xs text-white">{selectedCampaign?.id}</div>
          <div
            className="bg-slate-100 pb-6 w-full flex-col min-h-[960px]"
            style={{ background: theme.palette.secondary.light }}
          >
            {/*<AnimatePresence>*/}
            <motion.div
              initial={{ y: -80, opacity: 1 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                delay: 0.1,
                duration: 0.5,
              }}
            >
              <Header displayName={displayName} tenantLogo={tenantLogo} />
            </motion.div>
            <>
              <motion.div
                className="px-2 sm:px-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                  delay: 0.0,
                  duration: 0.5,
                }}
              >
                <motion.div
                  initial={{ x: 200, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{
                    ease: cubicBezier(0.21, 0.21, 0.42, 1.16),
                    delay: 0.6,
                    duration: 0.5,
                  }}
                  className="flex width-full items-center justify-center mb-2 mt-8 border-b-[1px] ml-4 mr-4"
                >
                  <div className="flex w-full text-5xl font-extralight uppercase text-slate-400 tracking-widest mb-2">
                    Insights
                  </div>
                </motion.div>
                <div className="flex ml-4">
                  <Crumbs tenantName={tenantName} />
                </div>
                <Selectors
                  isCampaignRendered={isCampaignRendered}
                  selectedCampaign={selectedCampaign}
                  selectedProgramName={selectedProgramName}
                  programNames={programNames}
                  handleCampaignChange={handleCampaignChange}
                  handlePartnerChange={handlePartnerChange}
                  selectedPartnerName={selectedPartnerName}
                  partnerNames={partnerNames}
                  years={years}
                  handleYearChange={handleYearChange}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                />
                <div className="flex w-full mt-0 mb-1 sm:mb-0 sm:mt-3 sm:ml-6 text-xs sm:text-2xl font-extralight tracking-widest text-slate-500 justify-center sm:justify-start">
                  {isCampaignRendered && selectedCampaign['name']}
                </div>

                {campaignTemplate === 1 && (
                  <CampaignTemplate01 isCampaignRendered={isCampaignRendered} selectedCampaign={selectedCampaign} />
                )}
                {campaignTemplate === 2 && (
                  <CampaignTemplate02 isCampaignRendered={isCampaignRendered} selectedCampaign={selectedCampaign} />
                )}
                {campaignTemplate === 3 && (
                  <CampaignTemplate03
                    isCampaignRendered={isCampaignRendered}
                    selectedCampaign={selectedCampaign}
                    handlePartnerChange={handlePartnerChange}
                    setSelectedPartnerName={setSelectedPartnerName}
                    campaignData={campaignData}
                    setCampaignTemplate={setCampaignTemplate}
                    setSelectedCampaignName={setSelectedCampaignName}
                    setCampaignNames={setCampaignNames}
                    setProgramNames={setProgramNames}
                    setSelectedProgramName={setSelectedProgramName}
                  />
                )}
                {campaignTemplate === 4 && (
                  <CampaignTemplate04 isCampaignRendered={isCampaignRendered} selectedCampaign={selectedCampaign} />
                )}

                {/*<div className="flex items-center justify-center shadow-md rounded-xl mx-4 mb-4 p-4 bg-white min-h-[320px]">*/}
                {/*  {isCampaignRendered ? <DataGridDemoMUI /> : <NoData />}*/}
                {/*</div>*/}
              </motion.div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPageV2;
